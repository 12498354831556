import { useQuery } from '@do/seashell/graphql';
import { style } from '@do/walrus';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GetAppsQuery from '../../graphql/queries/getApps.gql';
import {
  CATEGORY_GRID_BREAKPOINTS,
  SIDENAV_WIDTH
} from '../../utils/constants';
import filterAppsByCategory from '../../utils/filterAppsByCategory';
import { slugify } from '../../utils/slug-helpers';
import Pill from '../Pill';

const StyledH2 = styled.h2`
  font-size: 1.5rem;
  font-weight: ${style.vars.fontWeight.bold};
`;

const Container = styled.div`
  display: none;
  width: ${SIDENAV_WIDTH}px;
  padding-right: 30px;

  @media (${CATEGORY_GRID_BREAKPOINTS.ThreeCards}) {
    display: block;
  }

  a {
    display: block;
    margin-bottom: 8px;
    &.is-active {
      color: ${style.colors.grey.darkest};
      font-weight: 700;
    }
  }
`;

interface Props {
  categories: any;
  activeCategoryKey?: string;
  onClickCategory: (val: string) => void;
  solutions: any;
  activeSolutionKey?: string;
  onClickSolution: (val: string) => void;
}

const Sidenav: React.FC<Props> = ({
  categories,
  activeCategoryKey,
  onClickCategory,
  solutions,
  activeSolutionKey,
  onClickSolution
}) => {
  const allCategories = [...categories];
  const {
    loading: isLoading,
    error,
    data
  } = useQuery(GetAppsQuery, {
    /*
      network-only fetch policy will cause the
      page to flicker when the cache is updated.
      Using no-cache will prevent any flicker and
      always grab latest from the server.
    */
    fetchPolicy: 'no-cache',
    variables: {
      payload: {}
    }
  });

  const canShowCategoryCount = !isLoading && !error;
  const apps = data?.getApps?.apps || [];

  const [finalCategories, setFinalCategories] = useState(allCategories);

  useEffect(() => {
    if (canShowCategoryCount) {
      const filteredCategories = allCategories.filter((cat) => {
        const categoryCount = filterAppsByCategory(apps, cat.key)?.length;
        return categoryCount > 0;
      });
      setFinalCategories(filteredCategories);
    }
  }, [apps]);

  return (
    <Container>
      <StyledH2>Solution categories</StyledH2>
      {finalCategories?.map((cat, index) => {
        const categoryCount = filterAppsByCategory(apps, cat.key).length;
        const isActive =
          activeCategoryKey?.toLowerCase() === cat.key.toLowerCase();

        return (
          <Link
            key={index}
            href={`/category/[slug]`}
            as={`/category/${slugify(cat.key)}`}
            scroll={false}
            prefetch={false}
            className={isActive ? 'is-active' : undefined}
            onClick={onClickCategory.bind(this, cat.key)}
            data-testid={slugify(cat.key)}
          >
            {cat.text}{' '}
            <span>{canShowCategoryCount && `(${categoryCount})`}</span>{' '}
            {cat.is_new && <Pill text="NEW" small />}
          </Link>
        );
      })}
      <h3>Use cases</h3>
      {solutions?.map((solution) => (
        <Link
          key={solution.uid}
          href={`/solutions/${solution.uid}`}
          as={`/solutions/${solution.uid}`}
          scroll={false}
          prefetch={false}
          className={
            activeSolutionKey === solution.uid ? 'is-active' : undefined
          }
          onClick={onClickSolution.bind(this, solution.title[0].text)}
        >
          {solution.title[0].text}
        </Link>
      ))}
    </Container>
  );
};

export default Sidenav;
